<template>
  <v-main app>
    <v-container>
    <v-row justify="center">
      <v-col md="6" sm="12">
        <v-alert v-if="response === 'TOKEN_EXPIRED'" class="mt-16"
          border="top"
          colored-border
          type="warning"
          elevation="2"
        >
          <p>The password reset request has expired. It is only valid for 1 hour after the request is submitted. You can request a new one <a href="/requestPasswordReset">here</a></p>
        </v-alert>
        <v-alert v-if="response === 'BAD_TOKEN'" class="mt-16"
          border="top"
          colored-border
          type="warning"
          elevation="2"
        >
          <p>Bad Url Token. If you copy / pasted the url, please check that the entire link has been copied correctly</p>
        </v-alert>
        <v-alert v-if="response === 'BAD_DATA'" class="mt-16"
          border="top"
          colored-border
          type="warning"
          elevation="2"
        >
          <p>Bad Data</p>
        </v-alert>
        <v-alert v-if="response === 'PASSWORD_RESET'" class="mt-16"
          border="top"
          colored-border
          type="info"
          elevation="2"
        >
          <p>Your password has been reset - you can login <router-link to="/login">here</router-link></p>
        </v-alert>
        <v-card v-if="!response" class="mt-16">
          <v-card-title
            class="
              mb-4
              text-xs-center
              justify-center
              primary
              title
              white--text
              font-weight-bold
            "
          >
            Reset Password
          </v-card-title>
          <v-card-text>
            <v-form ref="resetPasswordForm" v-model="valid">
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    tabindex="1"
                    v-model="password"
                    name="password1"
                    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="[rules.required, rules.strength]"
                    :type="show1 ? 'text' : 'password'"
                    label="Password"
                    counter
                    @click:append="show1 = !show1"
                    dense
                  ></v-text-field>
                  <v-text-field
                    tabindex="2"
                    name="password2"
                    v-model="confirmPassword"
                    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="[rules.required, passwordMatch]"
                    :type="show1 ? 'text' : 'password'"
                    label="Confirm Password"
                    counter
                    @click:append="show1 = !show1"
                    dense
                  ></v-text-field>
                  <v-spacer></v-spacer>
                  <v-btn :disabled="!valid" color="success" @click="submit"
                    >Submit</v-btn
                  >
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    </v-container>
  </v-main>
</template>

<script>
import zxcvbn from 'zxcvbn'
const server = process.env.VUE_APP_API_URL || ''
const api = `${server}/api/public/login/resetPassword`
export default {
  data: () => ({
    valid: false,
    password: '',
    confirmPassword: '',
    response: null,
    rules: {
      emailRules: [
        (v) => !!v || 'Required',
        (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid'
      ],
      required: (value) => !!value || 'Required.',
      strength: (v) => (v && zxcvbn(v).score >= 3) || 'Please choose a stronger password. Try a mix of letters, numbers, and symbols.'
    },
    show1: false
  }),
  computed: {
    passwordMatch () {
      return () => {
        return this.password === this.confirmPassword || 'Password must match'
      }
    }
  },
  methods: {
    parseJwt (token) {
      try {
        return JSON.parse(window.atob(token.split('.')[1]))
      } catch (e) {
        return null
      }
    },
    submit () {
      this.$axios
        .post(api, {
          token: this.$route.params.token,
          password: this.password,
          confirmPassword: this.confirmPassword
        })
        .then((response) => {
          this.$store.dispatch('logout')
          this.$store.dispatch('auth/clearToken')
          this.response = response.data.msg
        })
        .catch((data) => {
          console.log('error', data)
        })
    }
  },
  async created () {
    const decodedJwt = this.parseJwt(this.$route.params.token)
    const expired = new Date().getTime() - decodedJwt.iat * 1000 > 60 * 60 * 1000
    if (expired) { this.response = 'TOKEN_EXPIRED' }
  }
}
</script>
<style scoped>
::v-deep .v-form .v-input {
  padding: 0px;
}
::v-deep input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  background-clip: text;
}
</style>
